import React from "react"
import Input from "../../../components/Input"
import Header from "./Header"
import Date from "../../../components/Date"
import Select from "../../../components/Select"
import styled from "styled-components"
import Nav from "./Nav"
import Footer from "./Footer"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const InnerWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  h3 {
    font-weight: 300;
    color: ${props => props.theme.colors.turq}
  }
`

const QuestionWrapper = styled.div`
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const HalfWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export default ({
    form,
    index,
    setIndex
}) => {

  const therapistOptions = [
    {id: 'amy', label: 'AMY'},
    {id: 'somethingelse', label: 'SOMETHING ELSE'}
  ]

    return (
        <div>
          <Header />
          <Wrapper>
            <InnerWrapper>
            <QuestionWrapper>
            <Date style={{width: '100%'}} name='date' label='DATE OF TREATMENT:'/>
            <Select form={form} name='therapist' label='MASSAGE THERAPIST' options={therapistOptions}/>
            </QuestionWrapper>
            <h3>CLIENT DETAILS</h3>
            <QuestionWrapper>
              <HalfWrapper>
                <Input label='NAME:' name='name' form={form} style={{ width: '65%', marginRight: 5 }} />
                <Date label='DOB:' name='dob' form={form} style={{ width: '35%' }} />
              </HalfWrapper>
              <HalfWrapper>
                <Input label='GENDER:' name='gender' form={form} style={{ width: '65%', marginRight: 5 }} />
                <Input label='HEIGHT:' name='height' form={form} style={{ width: '35%' }} />
              </HalfWrapper>
              <Input name='address1' style={{ marginBottom: -15 }} form={form} label='ADDRESS:' />
              <Input name='address2' form={form} label='ADDRESS:' labelStyle={{ opacity: 0 }}/>
              <Input name='email' labelStyle={{ width: '20%' }} form={form} label='EMAIL:' />
              <Input name='occupation' labelStyle={{ width: '20%' }} form={form} label='OCCUPATION:' />
              <HalfWrapper>
                <Input name='ename' style={{ marginRight: 5 }} labelStyle={{ width: '20vw' }} placeholder='NAME' form={form} label='EMERGENCY CONTACT:' />
                <Input name='enumber' placeholder='NUMBER'style={{ flexGrow: 1 }} form={form} label='' />
              </HalfWrapper>
            </QuestionWrapper>
            <Nav index={index} setIndex={setIndex}/>
            </InnerWrapper>
          </Wrapper>
          <Footer />
      </div>
    )
}