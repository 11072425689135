import React, { useEffect, useState } from "react"
import ClientContainer from "./ClientContainer"
import { getItem, updateItem } from "../services/Rest"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import Modal from "react-bootstrap/Modal"
import { useForm } from "react-hook-form"
import Button from "../components/Button"
import _ from "lodash"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  padding: 30px;
  gap: 10px;
`

const StyledField = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: 0;
  }

  span {
    cursor: pointer;
    color: blue;
  }
`

export default () => {

  const { setClient, client } = ClientContainer.useContainer()
  const [loading, setLoading] = useState(true)
  const [showModal ,setShowModal] = useState(false)
  const [modalLabel, setModalLabel] = useState("")
  const [nameToEdit, setNameToEdit] = useState("")
  const [value, setValue] = useState()


  useEffect(() => {
    console.log(client.name)
    if (client) {
      setLoading(false)
    }
  }, [client])

  
  const openModal = (label, name) => {
    if(showModal) return
    setModalLabel(label)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const onSave = () => {
    const clientCopy = _.cloneDeep(client)
    clientCopy[nameToEdit] = value

    updateItem('client', clientCopy).then((client) => {
      setClient(client)
    }).catch((e) => {
      console.log(e)
    })

  }

  const InformationField = ({name, label}) => {
    setNameToEdit(name)

    console.log(client[name])

    return (
      <>
        <StyledField>
          <p>{label}: {client[name]}</p>
          <span onClick={() => {openModal(label, name)}}>Edit</span>
        </StyledField>
      </>
    )
  }

  return (
    <Wrapper>
      {!loading &&
        <InnerWrapper>
          <InformationField label='Name' name='name'/>
          <p>email: {client.email}</p>
          <p>dob: {client.dob}</p>
          <p>gender: {client.gender}</p>
          <p>height: {client.height}</p>
          <p>address: {client.address1}</p>
          <p>occupation: {client.occupation}</p>
          <p>emergency contact name: {client.ename}</p>
          <p>emergency contact number: {client.enumber}</p>
        </InnerWrapper>
      }
    <Modal dialogClassName="custom-modal" show={showModal} onHide={() => {setShowModal(false)}}>
      <Modal.Header closeButton>
        <Modal.Title>Edit details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex' }}>
          <p style={{ marginRight: 10 }}>{modalLabel}:</p>
          <input type="text" value={value} defaultValue={client[nameToEdit]} onChange={(e) => {setValue(e.target.value)}}/>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button label='Cancel' onClick={closeModal}/>
        <Button label='Save' primary onClick={onSave}/>
      </Modal.Footer>
    </Modal>
    </Wrapper>
  )
}