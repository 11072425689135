import React from 'react'
import styled from 'styled-components'
import Header from "./Header"
import Checkbox from '../../../components/Checkbox'
import Input from '../../../components/Input'
import Nav from './Nav'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 20vw;
  margin-right: 20vw;
  padding-top: 30px;

  h3 {
    font-weight: 300;
    color: ${props => props.theme.colors.turq}
  }
`

const QuestionWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h4 {
    font-weight: 800;
    color: ${props => props.theme.colors.turq}
  }
`

const HalfWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
`

const InputWrapper = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
`

const StyledCheckbox = styled(Checkbox)`
  width: 45%;
`;

export default ({
  form,
  index,
  setIndex
}) => {

  return(
    <>
      <Header />
      <Wrapper>
        <QuestionWrapper>
          <HalfWrapper style={{ gap: 0, justifyContent: 'flex-start' }}>
            <h4 style={{ width: 30 }}>Q11.</h4>
            <h4 style={{ width: '90%' }}>HOW FREQUENTLY DO YOU EXERCISE IN A WEEK?</h4>
          </HalfWrapper>
        </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <StyledCheckbox name='exercise' label='NONE' form={form} type='radio' />
            <StyledCheckbox name='exercise' label='1-3 HOURS' form={form} type='radio' />
            <StyledCheckbox name='exercise' label='3-6 HOURS' form={form} type='radio' />
            <StyledCheckbox name='exercise' label='7+ HOURS' form={form} type='radio' />
          </HalfWrapper>
        </InputWrapper>
          <QuestionWrapper>
            <HalfWrapper style={{ gap: 0, justifyContent: 'flex-start' }}>
              <h4 style={{ width: 30 }}>Q12.</h4>
              <h4 style={{ width: '90%' }}>WHAT TYPE OF EXERCISE DO YOU DO?</h4>
            </HalfWrapper>
          </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <StyledCheckbox name='cardio' label='CARDIO' form={form} type='checkbox' />
            <StyledCheckbox name='yoga' label='YOGA/PILATES' form={form} type='checkbox' />
            <StyledCheckbox name='strength' label='STRENGTH TRAINING' form={form} type='checkbox' />
            <StyledCheckbox name='mobility' label='MOBILITY WORK' form={form} type='checkbox' />
            <StyledCheckbox name='spin' label='SPIN/RPM/CYCLE' form={form} type='checkbox' />
            <StyledCheckbox name='group' label='GROUP FITNESS CLASSES' form={form} type='checkbox' />
            <StyledCheckbox name='sports' label='SPORTS' form={form} type='checkbox' />
            <StyledCheckbox name='dance' label='DANCE' form={form} type='checkbox' />
            <StyledCheckbox name='resistance' label='RESISTANCE' form={form} type='checkbox' />
            <StyledCheckbox name='outdoor' label='OUTDOOR WALKS/RUNS' form={form} type='checkbox' />
            <StyledCheckbox name='selfDefence' label='SELF DEFENCE' form={form} type='checkbox' />
            <div style={{ width: '45%' }}>
              <Checkbox name='otherq12' label='OTHER' form={form} type='checkbox' />
              <Input name='otherTextQ12' form={form}/>
            </div>
          </HalfWrapper>
        </InputWrapper>
        <QuestionWrapper>
            <HalfWrapper style={{ gap: 0, justifyContent: 'flex-start' }}>
              <h4 style={{ width: 30 }}>Q13.</h4>
              <h4 style={{ width: '90%' }}>WHAT WOULD YOU LIKE TO ACCOMPLISH FROM YOUR TREATMENT?</h4>
            </HalfWrapper>
        </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <StyledCheckbox name='increasedMobility' label='INCREASED MOBILITY' form={form} type='checkbox' />
            <StyledCheckbox name='painRelief' label='PAIN RELIEF' form={form} type='checkbox' />
            <StyledCheckbox name='improvedRange' label='IMPROVED RANGE OF MOTION' form={form} type='checkbox' />
            <StyledCheckbox name='improvedPosture' label='IMPROVED POSTURE' form={form} type='checkbox' />
            <StyledCheckbox name='regSelfCare' label='REGULAR SELF CARE' form={form} type='checkbox' />
            <StyledCheckbox name='improveWellbeing' label='IMPROVE WELLBEING' form={form} type='checkbox' />
            <StyledCheckbox name='allOfTheAbove' label='ALL OF THE ABOVE' form={form} type='checkbox' />
            <StyledCheckbox name='reduceSwelling' label='REDUCE SWELLING, EDEMA, PUFFINESS &/OR FLUID BUILD-UP' form={form} type='checkbox' />
          </HalfWrapper>
        </InputWrapper>
        <Nav index={index} setIndex={setIndex}/>
      </Wrapper>
      <Footer />
    </>
  )
}