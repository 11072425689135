import React, { useState } from "react"
import { useForm } from "react-hook-form"
import ClientMedicalFormPage1 from "./ClientMedicalFormPage1"
import { Form } from "react-bootstrap"
import ClientMedicalFormPage6 from "./ClientMedicalFormPage6"
import ClientMedicalFormPage2 from "./ClientMedicalFormPage2"
import ClientMedicalFormPage3 from "./ClientMedicalFormPage3"
import ClientMedicalFormPage4 from "./ClientMedicalFormPage4"
import ClientMedicalFormPage5 from "./ClientMedicalFormPage5"
import { createItem } from "../../../services/Rest"

export default () => {

  const [index, setIndex] = useState(0)

  const form = useForm({
    defaultValues:{}
  })

  const onSubmit = () => {
    const client = form.getValues()
    Object.keys(client).forEach(key => client[key] === undefined && delete client[key])
    createItem('client', client).then((client) => {
      console.log(client)
    }).catch((e) => {
      console.log(e)
    })
  }

  return (
    <Form onSubmit={() => {console.log('submite')}}>
      {index === 0 && <ClientMedicalFormPage1 index={index} setIndex={setIndex} form={form}/>}
      {index === 1 && <ClientMedicalFormPage2 index={index} setIndex={setIndex} form={form}/>}
      {index === 2 && <ClientMedicalFormPage3 index={index} setIndex={setIndex} form={form}/>}
      {index === 3 && <ClientMedicalFormPage4 index={index} setIndex={setIndex} form={form}/>}
      {index === 4 && <ClientMedicalFormPage5 index={index} setIndex={setIndex} form={form}/>}
      {index === 5 && <ClientMedicalFormPage6 index={index} setIndex={setIndex} form={form} onSubmit={onSubmit}/>}
    </Form>
  )
}