import next from '../../../assets/nextPage.png'
import previous from '../../../assets/prevPage.png'
import {
    nav1,
    nav2,
    nav3,
    nav4,
    nav5,
    nav6
} from '../../../assets'
import styled from 'styled-components'

const NavButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100px;
`

const NavButton = styled.img`
  height: 80px;
  width: 80px;
  cursor: pointer;
`

const SubmitButton = styled.div`
  background-color: ${props => props.theme.colors.orange};
  padding: 15px 20px;
  border-radius: 30px;
  cursor: pointer;

  p{
    color: white;
  }
`

export default ({
    index,
    setIndex,
    onSubmit
}) => {
    let navArray = [nav1, nav2, nav3, nav4, nav5, nav6]

    
    const onNext = () => {
      setIndex(index + 1)
      window.scrollTo(0, 0)
    }

    const onPrevious = () => {
      if (index === 0) return
      setIndex(index - 1)
      window.scrollTo(0, 0)
    }

    return(
    <NavButtonsWrapper>
        <NavButton src={previous} style={{ opacity: index === 0 ? 0 : 1 }} onClick={onPrevious}/>
        {index == 5 ? <SubmitButton onClick={onSubmit}><p>CONFIRM & SUBMIT</p></SubmitButton> : <NavButton src={next} onClick={onNext}/>}
    </NavButtonsWrapper>
    )
}