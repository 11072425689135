import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, Routes } from "react-router-dom";
import ServiceView from "./website/service/ServiceView";
import AboutView from "./website/about/AboutView";
import ContactView from "./website/contact/ContactView";
import ClientsView from './client/ClientsView'
import ClientMedicalForm from "./client/forms/medicalForm/ClientMedicalForm";
import Client from './client/Client'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HomeView from './website/Home/HomeView';



function App() {
    return (
        <>
            <ToastContainer className='custom-toast'/>
            <Routes>
                <Route path="/" element={<HomeView />} />
                <Route path="/service" element={<ServiceView />} />
                <Route path="/about" element={<AboutView />} />
                <Route path="/contact" element={<ContactView />} />
                <Route path="/client" element={<ClientsView />} />
                <Route path="/client/form" element={<ClientMedicalForm />} />
                <Route path="/client/:id" element={<Client />} />
            </Routes>
        </>
    );
}

export default App;
