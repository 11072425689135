import React, { useEffect } from 'react'
import Header from "./Header"
import styled from 'styled-components'
import Checkbox from '../../../components/Checkbox'
import Input from '../../../components/Input'
import Nav from './Nav'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const InnerWrapper = styled.div`
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 30px;
  h3 {
    margin-right: 5px;
    font-weight: 300;
    color: ${props => props.theme.colors.turq}
  }
`

const QuestionWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  h5 {
    font-weight: 500;
    color: ${props => props.theme.colors.turq}
  }
  h4 {
    font-weight: 800;
    color: ${props => props.theme.colors.turq}
  }
`

const InputWrapper = styled.div`
  margin-left: 50px;
  margin-right: 35px;
  display: flex;
  flex-direction: column;
  h5 {
    font-weight: 500;
    color: ${props => props.theme.colors.turq};
    margin-bottom: 14px;
    margin-top: 16px;
  }
  h4 {
    margin-bottom: 4px;
    font-weight: 600;
    color: ${props => props.theme.colors.turq}
  }
`

const HalfWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
`

export default ({
  form,
  index,
  setIndex
}) => {

  const injuries = form.watch('injuries')

  useEffect(() => {
    console.log(injuries)
  }, [injuries])

  return (
    <div>
      <Header />
      <Wrapper>
        <InnerWrapper>
          <h3>CLIENT HISTORY</h3>
          <QuestionWrapper>
            <h5>Please tick the following questions</h5>
            <HalfWrapper>
              <h4>Q1.</h4>
              <h4>ARE YOU CURRENTLY EXPERIENCING ANY PAIN, DISCOMFORT, RECENT INJURIES, AND/OR SURGERY</h4>
            </HalfWrapper>
          </QuestionWrapper>
          <InputWrapper>
            <HalfWrapper>
              <Checkbox name='injuries' value={true} label='YES' type='radio' form={form} style={{ marginRight: '20%' }} />
              <Checkbox name='injuries' value={false} label='NO' type='radio' form={form} />
            </HalfWrapper>
            <h5>If selected 'yes', please provide more information</h5>
            <h4>DETAILS (LOCATION/AREA, WHEN DID IT START ETC.)</h4>
            <Input style={{ marginBottom: 12 }} name='injuryDetails' form={form} label='' />
            <h4 style={{ marginBottom: 10 }}>HAVE YOU SEEN A GP OR SPECIALIST</h4>
            <HalfWrapper style={{ marginBottom: 14 }}>
              <Checkbox name='gp' label='YES' type='radio' form={form} style={{ marginRight: '20%' }} />
              <Checkbox name='gp' label='NO' type='radio' form={form} />
            </HalfWrapper>
            <h4>DETAILS</h4>
            <Input name='gpdetails' form={form} label='' />
          </InputWrapper>
          <QuestionWrapper>
            <HalfWrapper>
              <h4>Q2.</h4>
              <h4>DO YOU HAVE ANY ALLERGIES, SENSITIVITIES/INTOLERANCES?</h4>
            </HalfWrapper>
          </QuestionWrapper>
          <InputWrapper>
            <HalfWrapper>
              <Checkbox name='allergies' label='YES' type='radio' form={form} style={{ width: '30%' }} />
              <Checkbox name='allergies' label='NO' type='radio' form={form} style={{ width: '30%' }} />
            </HalfWrapper>
            <h5>If selected 'yes', please provide more information</h5>
            <h4>DETAILS</h4>
            <Input name='allergiesdetails' form={form} label='' />
          </InputWrapper>
          <Nav index={index} setIndex={setIndex}/>
        </InnerWrapper>
      </Wrapper>
      <Footer />
    </div>
  )
}