import React from 'react'
import styled from 'styled-components'
import Header from "./Header"
import Checkbox from '../../../components/Checkbox'
import Input from '../../../components/Input'
import Nav from './Nav'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 20vw;
  margin-right: 20vw;
  padding-top: 30px;

  h3 {
    font-weight: 300;
    color: ${props => props.theme.colors.turq}
  }
`

const QuestionWrapper = styled.div`
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h4 {
    font-weight: 800;
    color: ${props => props.theme.colors.turq}
  }
`

const HalfWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
`

const InputWrapper = styled.div`
  margin-left: 35px;
  display: flex;
  flex-direction: column;
`

const StyledCheckbox = styled(Checkbox)`
  width: 45%;
`;

export default ({
  form,
  index,
  setIndex
}) => {

  return(
    <>
      <Header />
      <Wrapper>
        <QuestionWrapper>
          <HalfWrapper style={{ gap: 0, justifyContent: 'flex-start' }}>
            <h4 style={{ width: 30 }}>Q3.</h4>
            <h4 style={{ width: '90%' }}>ARE YOU CURRENTLY EXPERIENCING OR EVER EXPERIENCED THE FOLLOWING?</h4>
          </HalfWrapper>
        </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <StyledCheckbox name='arthritis' label='ARTHRITIS' form={form} type='checkbox' />
            <StyledCheckbox name='bloodPressure' label='HIGH/LOW BLOOD PRESSURE' form={form} type='checkbox' />
            <StyledCheckbox name='dizziness' label='DIZZINESS' form={form} type='checkbox' />
            <StyledCheckbox name='asthma' label='ASTHMA' form={form} type='checkbox' />
            <StyledCheckbox name='fatigue' label='FATIGUE' form={form} type='checkbox' />
            <StyledCheckbox name='ibs' label='IRRITABLE BOWEL SYNDROME' form={form} type='checkbox' />
            <StyledCheckbox name='lympathic' label='LYMPATHIC DISORDERS' form={form} type='checkbox' />
            <StyledCheckbox name='sciatica' label='SCIATICA' form={form} type='checkbox' />
            <StyledCheckbox name='headaches' label='HEADACHES / MIGRAINES' form={form} type='checkbox' />
            <StyledCheckbox name='osteoperosis' label='OSTEOPEROSIS' form={form} type='checkbox' />
            <StyledCheckbox name='coldSores' label='COLD SORES' form={form} type='checkbox' />
            <StyledCheckbox name='fungalBacterial' label='FUNGAL/BACTERIAL INFECTIONS' form={form} type='checkbox' />
            <StyledCheckbox name='aep' label='ACNE, ECZEMA, PSORIASES' form={form} type='checkbox' />
          </HalfWrapper>
        </InputWrapper>
          <QuestionWrapper>
            <HalfWrapper style={{ gap: 0, justifyContent: 'flex-start' }}>
              <h4 style={{ width: 30 }}>Q4.</h4>
              <h4 style={{ width: '90%' }}>DO YOU HAVE ANY OF THE FOLLOWING?</h4>
            </HalfWrapper>
          </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <StyledCheckbox name='anxiety' label='ANXIETY' form={form} type='checkbox' />
            <StyledCheckbox name='depression' label='DEPRESSION' form={form} type='checkbox' />
            <StyledCheckbox name='diabetes1' label='DIABETES TYPE 1' form={form} type='checkbox' />
            <StyledCheckbox name='diabetes2' label='DIABETES TYPE 2' form={form} type='checkbox' />
            <StyledCheckbox name='liverDisease' label='LIVER DISEASE' form={form} type='checkbox' />
            <StyledCheckbox name='epilepsy' label='EPILEPSY' form={form} type='checkbox' />
            <StyledCheckbox name='heartDisease' label='HEART DISEASE/HEART CONDITIONS' form={form} type='checkbox' />
            <StyledCheckbox name='fractures' label='FRACTURES, DISLOCATIONS, BROKEN BONES, INJURY' form={form} type='checkbox' />
            <StyledCheckbox name='gastro' label='GASTROINTESTINAL / DIGESTIVE ISSUES' form={form} type='checkbox' />
            <div style={{ width: '45%' }}>
              <Checkbox name='otherq3' label='OTHER' form={form} type='checkbox' />
              <Input name='otherTextQ3' form={form}/>
            </div>
          </HalfWrapper>
        </InputWrapper>
        <Nav index={index} setIndex={setIndex}/>
      </Wrapper>
      <Footer />
    </>
  )
}