import React, {useEffect, useState} from 'react'
import InputWrapper from './InputWrapper'
import GetValue from './GetValue'

export default ({
    name,
    form,
    type,
    value,
    defaultValue,
    label,
    style,
    labelStyle,
    disabled,
    className
}) => {

    const [checked, setChecked] = useState(false)

    const onChange = (e) => {
        setChecked(e.target.checked)
        form.setValue(name, value)
    }

    useEffect(() => {
        if (!form) return
        form.register(name)
        form.setValue(name, GetValue(form, name) || defaultValue)
    }, [])
    
    return(
        <div style={style} className={className}>
            <InputWrapper style={labelStyle} type={type} label={label}>
                <input disabled={disabled} id={name} name={name} onChange={onChange} value={value} checked={checked} type={type === 'checkbox' ? 'checkbox' : 'radio'}/>
            </InputWrapper>
        </div>
    )
}