import React, {useEffect, useState} from 'react'
import InputWrapper from './InputWrapper'
import GetValue from './GetValue'
import styled from 'styled-components'

const Date = styled.input`
  width: 100%;
  height: 30px;
  border-radius: 20px;
  border: 0;
  background-color: ${props => props.theme.colors.input};
  padding: 5px 10px;
`

export default ({
    name,
    form,
    defaultValue,
    label,
    style
}) => {

  const [date, setDate] = useState()

    const onChange = (e) => {
        setDate(e.target.value)
        form.setValue(name, e.target.value)
    }

    useEffect(() => {
        if (!form) return
        form.register(name)
        form.setValue(name, GetValue(form, name) || defaultValue)
    }, [])
    
    return(
      <div style={style}>
        <InputWrapper label={label}>
            <Date onChange={onChange} value={date} type='date'/>
        </InputWrapper>
      </div>
    )
}