import logo from "../../../assets/logo.png"
import styled from "styled-components"

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: center;
`

const SpaceAboveSvg = styled.div`
    width: 100%;
    height: 8vh;
    background-color: #4fb1a1;
`

const Logo = styled.img`
    width: 15%;
    position: absolute;
    left: 65%;
    top: 0.5%;
`

const Header = styled.h1`
    text-align: center;
    border-bottom: solid;
    color: #4fb1a1;
    width: 70%;
`

export default () => {
    return (
        <Wrapper>
            <SpaceAboveSvg>
                <Logo src={logo} alt="logo"/>
            </SpaceAboveSvg>
            <svg style={{ marginTop: -2 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 200"><path fill="#4fb1a1" d="M0,128L80,117.3C160,107,320,85,480,101.3C640,117,800,171,960,176C1120,181,1280,139,1360,117.3L1440,96L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"></path></svg>
            <Header>CLIENT INTAKE FORM</Header>   
        </Wrapper>
    )
}