import React, { useEffect, useState } from 'react'
import { getItems } from '../services/Rest'
import { Link, useNavigate, useNavigation } from 'react-router-dom'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import ClientContainer from './ClientContainer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default ({}) => {

  const [clients, setClients] = useState([])
  const { client, setClient } = ClientContainer.useContainer()
  const navigate = useNavigate()

  useEffect(() => {
    getItems('client').then((client) => {
      console.log(client)
      setClients(client)
    })
  }, [])

  return (
    <Wrapper>
      {clients.map((client) => {
        const onClick = () => {
          setClient(client)
          navigate(`/client/${client.id}`)  
        }
        return (
          <p onClick={onClick}>{client.name}</p>
        )})}
    </Wrapper>
  )
}