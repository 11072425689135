import React, {useEffect, useState} from 'react'
import InputWrapper from './InputWrapper'
import GetValue from './GetValue'
import styled from 'styled-components'

const Select = styled.select`
width: 100%;
height: 35px;
border-radius: 20px;
border: 0;
background-color: #afddd8;
padding: 5px 10px;
`

export default ({
    name,
    form,
    options,
    defaultValue,
    label
}) => {

  const [value, setValue] = useState()

    const onChange = (e) => {
      setValue(e.target.value)
        form.setValue(name, e.target.value)

    }

    useEffect(() => {
        if (!form) return
        form.register(name)
        form.setValue(name, GetValue(form, name) || defaultValue)
    }, [])
    
    return(
        <InputWrapper label={label}>
            <Select onChange={onChange} value={value}>
              {options.map((option) => (
                <option key={option.id} value={option.id}>{option.label}</option>
              ))}
            </Select>
        </InputWrapper>
    )
}