import React from 'react'
import Header from "./Header"
import styled from 'styled-components'
import Checkbox from '../../../components/Checkbox'
import Input from '../../../components/Input'
import Nav from './Nav'
import Footer from './Footer'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 20vw;
  margin-right: 20vw;
  padding-top: 30px;

  h3 {
    font-weight: 300;
    color: ${props => props.theme.colors.turq}
  }
`

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 20px;

  h5 {
    font-weight: 500;
    color: ${props => props.theme.colors.turq}
  }
  h4 {
    font-weight: 800;
    color: ${props => props.theme.colors.turq}
  }
`

const InputWrapper = styled.div`
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  flex-direction: column;
  h5 {
    font-size: 15px;
    font-weight: 500;
    color: ${props => props.theme.colors.turq};
    margin-bottom: 14px;
    margin-top: 16px;
  }
  h4 {
    margin-top: 10px;
    margin-bottom: 4px;
    font-weight: 600;
    color: ${props => props.theme.colors.turq}
  }
`

const HalfWrapper = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
`

export default ({
  form,
  index,
  setIndex
}) => {

  return (
    <div>
      <Header />
      <Wrapper>
        <QuestionWrapper>
          <HalfWrapper>
            <h4 style={{ width: 30 }}>Q5.</h4>
            <h4 style={{ width: '90%' }}>ARE YOU ON ANY MEDICATION?</h4>
          </HalfWrapper>
        </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <Checkbox name='medication' label='YES' type='radio' form={form} style={{ marginRight: '20%' }} />
            <Checkbox name='medication' label='NO' type='radio' form={form} />
          </HalfWrapper>
          <h4>DETAILS</h4>
          <Input style={{ marginBottom: 12 }} name='medicationDetails' form={form} label='' />
        </InputWrapper>
        <QuestionWrapper>
          <HalfWrapper>
            <h4 style={{ width: 30 }}>Q6.</h4>
            <h4>HAVE YOU HAD ANY RECENT OR REOCCURING INFECTIONS OR VIRUSES?</h4>
          </HalfWrapper>
        </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <Checkbox name='reoccurringInfections' label='YES' type='radio' form={form} style={{ marginRight: '20%' }} />
            <Checkbox name='reoccurringInfections' label='NO' type='radio' form={form} />
          </HalfWrapper>
          <h4>DETAILS</h4>
          <Input style={{ marginBottom: 12 }} name='reoccurringInfectionsDetails' form={form} label='' />
        </InputWrapper>
        <QuestionWrapper>
          <HalfWrapper>
            <h4 style={{ width: 30 }}>Q7.</h4>
            <h4>DO YOU SMOKE OR VAPE?</h4>
          </HalfWrapper>
        </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <Checkbox name='smoke' label='YES' type='radio' form={form} style={{ marginRight: '20%' }} />
            <Checkbox name='smoke' label='NO' type='radio' form={form} style={{ marginRight: '20%' }} />
            <Checkbox name='smoke' label='SOMETIMES' type='radio' form={form} />
          </HalfWrapper>
        </InputWrapper>
        <QuestionWrapper>
          <HalfWrapper>
            <h4 style={{ width: 30 }}>Q8.</h4>
            <h4>ARE YOU PREGNANT OR GIVEN BIRTH RECENTLY?</h4>
          </HalfWrapper>
        </QuestionWrapper>
        <InputWrapper>
          <HalfWrapper>
            <Checkbox name='pregnant' label='YES' type='radio' form={form} style={{ marginRight: '20%' }} />
            <Checkbox name='pregnant' label='NO' type='radio' form={form} />
          </HalfWrapper>
          <h5>If selected yes, how many weeks are you?</h5>
          <Input style={{ marginBottom: 12 }} name='pregnantDetails' form={form} label='' />
        </InputWrapper>
        <Nav index={index} setIndex={setIndex}/>
      </Wrapper>
      <Footer />
    </div>
  )
}